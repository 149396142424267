import React from 'react'
import "../PlanesAccion/HomePlanesAccion.scss"
import { jwtDecode } from "jwt-decode"
import ListaConsultorasAudit from '../../components/PlanesAuditoria/ListaConsultorasAudit'
import ListaEmpresasAudit from "../../components/PlanesAuditoria/ListaEmpresasAudit"
import GanttAudit from './GanttAudit'

function HomeAuditoria() {
    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const title = (
        <>
            <div className='section__header d-flex flex-row align-items-end mb-4'>
                <i className='bi bi-bar-chart-steps me-2'></i>
                <h4 className='m-0'>Plan de auditoría</h4>
            </div>
        </>
    )

    if(USER.puesto === 1){
        return (
            <div className='home__PA section'>
                {title}
                <ListaConsultorasAudit user={USER}/>
            </div> 
        )
    }

    if(USER.puesto === 2) {
        return (
            <div className='home__PA section'>
                {title}
                <ListaEmpresasAudit user={USER}/>
            </div>
        )
    }

    if(USER.puesto === 3 || USER.puesto === 4 || USER.puesto === 5) {
        return (
            <GanttAudit />
        )
    }
}

export default HomeAuditoria