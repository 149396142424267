import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './CarruselListas.scss'
import '../pages/Home/Home.scss'

const CarruselListaConsultoras = ({ items, fn}) => {
  return (
    <>
        {items.length > 4 ? (
            <div className='swiper-container'>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
                <Swiper 
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={16} 
                    slidesPerView={1}
                    breakpoints={{
                        768: {slidesPerView: 2},
                        992: {slidesPerView: 4}
                    }}
                    navigation={{
                        prevEl: ".swiper-button-prev",
                        nextEl: ".swiper-button-next",
                    }}
                    pagination={{ el: ".swiper-pagination-lista", clickable: true }}
                >
                    {items.map((e,i) => {
                        return <SwiperSlide key={i} >
                            <div onClick={()=> fn(e.id_consultora)} className='home__consultoras__consultora--slider border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center'>
                                <h4 className='p-0 m-0 text-start'>{e.nombre}</h4>
                            </div>
                        </SwiperSlide>
                    })}
                </Swiper>
                <div className="swiper-pagination-lista"></div>
            </div>
        ) : (
            <>
                {items.map((e,i) => {
                    return <div key={i} onClick={()=> fn(e.id_consultora)} className='home__consultoras__consultora border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center'>
                        <h4 className='p-0 m-0 text-start'>{e.nombre}</h4>
                    </div>
                })}
            </>
        )}
    </>
  )
}

export default CarruselListaConsultoras