import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from "./Navbar"
// import Footer from "./Footer"
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import "./Layout.scss"

import BtnDroxyAI from './BtnDroxyAI'

function Layout() {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const handleBack = () => {
        navigate(-1)
    }

    const hiddenPaths = ['/home']
    const showBackButton = !hiddenPaths.includes(location.pathname)

    return (
        <div className={sidebarOpen ? "sidebarOpen active" : "sidebarOpen"}>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
            <div className="main">
                <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
                <div className='main__outlet__container bg-white'>
                    {showBackButton && <button className='btn__locationBack btn p-0 mb-3 border-0' onClick={handleBack}><i className="bi bi-chevron-left"></i> <span>atrás</span></button>}
                    <Outlet />
                </div>
            </div>
            <BtnDroxyAI />
        </div>
    )
}

export default Layout