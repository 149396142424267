import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import "./ListaOkr.scss"
import "../../components/Okr/ListaConsultorasOkr.scss"
import { jwtDecode } from "jwt-decode"
import { Oval } from 'react-loader-spinner'
import { ProgressBar, Alert } from 'react-bootstrap';

import CarruselArea from '../../components/CarruselArea'

import { OkrContext } from '../../context/OkrContext'

import { usePlanes } from '../../context/PlanesContext';

import ModalNewOkr from '../../components/Modales/ModalNewOkr'

export const listaContext = React.createContext()

function ListaOkr() {
  const { empresa, fetchEmpresa, fetchEmpresaSinConsultora, area, fetchArea } = usePlanes()
  const { idConsult, id, idArea } = useParams()

  const { fetchOkrAreas, errorFetch, setErrorFetch } = useContext(OkrContext)

  const [loadingAreas, setLoadingAreas] = useState(true)
  const [errorAreas, setErrorAreas] = useState(null)

  const [okrAreas, setOkrAreas] = useState([])

  const [modalNewOkr, setModalNewOkr] = useState(false)

  const [promedioAreas, setPromedioAreas] = useState(0)

  const auth = localStorage.getItem("token")
  const jwtParse = jwtDecode(auth)
  const USER = jwtParse.apirest.objeto

  useEffect(() => {
    const firstFetch = () => {
      fetchOkrAreas(USER, idArea)
      .then(res => {
          if(res.error !== 0){
              setLoadingAreas(false)
              setErrorAreas(res.errorDetalle)
          } else {
              setLoadingAreas(false)
              setOkrAreas(res.objeto)
              // promedio de okrs totales
              const arrFiltrado = res.objeto.reduce((sum, item) => sum + Math.round(parseInt(item.progresoGeneral)), 0)
              const promedio = res.objeto.length > 0 ? arrFiltrado / res.objeto.length : 0
              setPromedioAreas(promedio)
          }
      })
    }

    firstFetch()
    if (USER.puesto < 3) {
      if(parseInt(idConsult) === 0){
        fetchEmpresaSinConsultora(USER, id)
      } else {
          fetchEmpresa(USER, idConsult, id)
      }
    }
    fetchArea(USER, id, idArea)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleNewOkr = (e) => {
    e.preventDefault()
    setModalNewOkr(true)
  }

  // const ruta = USER.puesto >= 3 ? "" : `/consultora/${idConsult}/empresa/${id}`
  let ruta;
  if(USER.puesto === 1){
    ruta = `/consultora/${idConsult}/empresa/${id}`
  }
  if(USER.puesto === 2){
    ruta = `/empresa/${id}`
  }
  if(USER.puesto >= 3){
    ruta = ""
  }

  return (
    <>
      <listaContext.Provider value={{setOkrAreas, setLoadingAreas, setErrorAreas}}>
        <ModalNewOkr show={modalNewOkr} onHide={()=>setModalNewOkr(false)} />
        <div className='okr section'>
          <div className='section__header d-flex flex-row align-items-end mb-4'>
            <i className='bi bi-list-check me-2'></i>
            <h4 className='m-0'><Link to={'/okr'} className='breadcrumb__generico'>OKR</Link> {USER.puesto < 3 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}`}>{`${empresa}`}</Link></>} <i className="bi bi-chevron-right"></i> {area}</h4>
          </div>
          {errorFetch && (
            <Alert variant='danger' onClose={() => setErrorFetch(null)} dismissible>
              {errorFetch}
            </Alert>
          )}
          {/* Botón de agregar OKR */}
          {(USER.puesto < 5 && USER.puesto >= 1) && <div className='okr__title mb-4 d-flex flex-row align-items-center justify-content-between'>
              <h3 className='m-0 text-blue-500'>Listado de Okr's</h3>
              <button 
                className='btn__newOkr btn btn-primary rounded-pill shadow-sm fw-medium' 
                onClick={handleNewOkr}
              >
                Agregar OKR
              </button>
            </div>
          }
          <div className='okr__main'>
            {/* OKR POR AREAS */}
            <div className='okr__main__areas'>
              {loadingAreas ? (
                <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                  <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="#0d6efd"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                  />
                  <p className='fw-medium'>Loading...</p>
                </div>
              ) : (
                <>
                  {errorAreas ? (
                    <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                      <h2 className='text-danger mb-1'>Oops!</h2>
                      <p className='m-0'>{errorAreas}</p>
                    </div>
                  ) : (
                    <>
                      <h4 className='mb-3 text-blue-500'>Objetivos del área</h4>
                      {okrAreas.length === 0 ? (
                        <div className='okr--empty d-flex flex-column align-items-center justify-content-center rounded-3 p-4 mb-4'>
                          <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes OKR's del área para mostrar.</h3>
                          {(USER.puesto < 5 && USER.puesto >= 1) && <p className='m-0 text-center'>Para comenzar, ingresa tu primer OKR.</p>} 
                        </div>
                      ) : (
                        <div className='mb-4'>
                          <ProgressBar className='mb-4 progreso__bar progreso__bar--global' now={Math.round(promedioAreas)} label={`${Math.round(promedioAreas)}%`} max={100}/>
                          <CarruselArea items={okrAreas} user={USER}/>
                        </div>
                      )}
                    </>
                  )}
                </>
              )} 
            </div>
          </div>
        </div>
      </listaContext.Provider>
    </>
  )
}

export default ListaOkr