/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react'
import "./BtnDroxyAI.scss"
import logoDroxy from "../../assets/img/logo-droxy.png"

function BtnDroxyAI() {
    const [openModal, setOpenModal] = useState(false)

    return (
        <div className={`container__chatbot ${openModal ? "show__chatbot" : ""}`}>
            <button onClick={()=>setOpenModal((prev) => !prev)} className='btnDroxyAI btn btn-primary rounded-circle'>
                <i className='bi bi-lightning-charge-fill'></i>
                <i className='bi bi-x-lg'></i>
            </button>
            <div className='modalDroxyAI border border-light-subtle shadow-sm rounded-3'>
                <div className='modalDroxyAI__header d-flex flex-row align-items-center justify-content-between p-3'>
                    <div className='d-flex flex-row align-items-center'>
                        <i className='bi bi-lightning-charge-fill pe-1'></i>
                        <p className='m-0 p-0 fw-medium'>Asistente IA</p>
                    </div>
                    <button className='btn__close btn p-0' onClick={()=>setOpenModal((prev) => !prev)}><i className='bi bi-x-lg'></i></button>
                </div>
                <div className='modalDroxyAI__body'>
                    <iframe
                        src="https://app.droxy.ai/guest-agent/6754ff6cc193877f06efbb1e"
                        width="100%"
                        height="100%"
                        frameborder="0">
                    </iframe>
                </div>
                <div className='modalDroxyAI__footer d-flex flex-row align-items-center justify-content-center border-top'>
                    <span className='m-0 me-1'>Powered by</span>
                    <a href='https://www.droxy.ai/' target='_blank' rel="noreferrer">
                        <img src={logoDroxy} alt='Logo Droxy'/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default BtnDroxyAI