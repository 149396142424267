import React, { useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode"
import { Link } from "react-router-dom"
import "./ListaEmpresas.scss"
import { Oval } from 'react-loader-spinner'
import IllustrationAccess from "../../assets/img/access.png"

function ListaEmpresasPA() {
    const [loadingEmpresas, setLoadingEmpresas] = useState(false)
    const [errorEmpresas, setErrorEmpresas] = useState(null)
    const [empresas, setEmpresas] = useState([])

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const fetchEmpresas = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/empresasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setErrorEmpresas(data.errorDetalle)
                setLoadingEmpresas(false)
            } else {
                setEmpresas(data.objeto)
                setLoadingEmpresas(false)
            }
        } catch (error) {
            setErrorEmpresas("Error: Conexión con backend.")
            setLoadingEmpresas(false)
        }
    }

    useEffect(()=>{
        fetchEmpresas()
    },[])

    return (
        <>
            {loadingEmpresas ? (
                <div className='loading__proyectos d-flex flex-column align-items-center justify-content-center'>
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#0d6efd"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    <p className='fw-medium'>Loading...</p>
                </div>
            ) : (
                <>
                    {errorEmpresas ? (
                        <div className='areas__proyectos--error d-flex flex-column align-items-center justify-content-center rounded-3'>
                            <img className='mb-4' src={IllustrationAccess} alt="" />
                            <h2 className='text-danger fw-semibold mb-1 text-center'>Oops!</h2>
                            <p className='text-center'>{errorEmpresas}</p>
                            <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
                        </div>
                    ) : (
                        <>
                            {empresas.length === 0 ? (
                                <div className='areas__proyectos--empty d-flex flex-column align-items-center justify-content-center'>
                                    <img className='mb-4' src={IllustrationAccess} alt="" />
                                    <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes empresas asignadas.</h3>
                                    <p className='text-center'>Contactar al equipo de soporte para el manejo de empresas.</p>
                                    <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
                                </div>
                            ) : (
                                <div className='table__custom__emp'>
                                    <div className='table__custom__emp__header'>
                                        <div className='table__custom__emp__cell table__custom__emp__cell--title fw-bold cell__id text-center'></div>
                                        <div className='table__custom__emp__cell table__custom__emp__cell--title fw-bold cell__name'>Nombre</div>
                                    </div>
                                    <div className='table__custom__emp__body'>
                                        {empresas.map((e, i) => {
                                        return <Link key={i} className='table__custom__emp__row text-decoration-none text-dark' to={`/planes-de-accion/consultora/${USER.idConsultora}/empresa/${e.id_empresa}`}>
                                            <div className='table__custom__emp__cell cell__id fw-bold text-center'>{i+1}</div>
                                            <div className='table__custom__emp__cell cell__name'>{e.nombre}</div>
                                        </Link>
                                        })}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default ListaEmpresasPA