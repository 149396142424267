import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '../pages/Login/Login'
import Home from '../pages/Home/Home'

import ProtectedRoutes from '../Services/ProtectedRoutes'
import Layout from '../components/Layout/Layout'
import PowerBI from '../components/PowerBI'
import NotFound from '../pages/NotFound/NotFound'
import ResetPassword from '../pages/ResetPassword/ResetPassword'

import HomePlanesAccion from '../pages/PlanesAccion/HomePlanesAccion'
import Gantt from '../pages/PlanesAccion/Gantt'
import Proyectos from '../pages/PlanesAccion/Proyectos'
import Perfil from '../pages/Perfil/Perfil'
import Areas from '../pages/Home/Areas'
import AreasPA from '../pages/PlanesAccion/AreasPA'

import HomeDatIN from '../pages/DatIN/HomeDatIN'
import AreasDatIN from '../pages/DatIN/AreasDatIN'
import DatIN from '../pages/DatIN/DatIN'

import HomeOkr from '../pages/Okr/HomeOkr'
import AreasOkr from '../pages/Okr/AreasOkr'
import ListaOkr from '../pages/Okr/ListaOkr'
import DetalleOkr from '../pages/Okr/DetalleOkr'
import DetalleOkrPersonal from '../pages/Okr/DetalleOkrPersonal'
import DetalleKeyResult from '../pages/Okr/DetalleKeyResult'

import HomeAuditoria from '../pages/PlanesAuditoria/HomeAuditoria'
import GanttAudit from '../pages/PlanesAuditoria/GanttAudit'
import Ciclos from '../pages/PlanesAuditoria/Ciclos'

import PrivateRoutesBank from '../Services/PrivateRoutesBank'

import { PlanesProvider } from '../context/PlanesContext'
import { OkrProvider } from '../context/OkrContext'

function MyRoutes() {
  return (
    <BrowserRouter>
      <PlanesProvider>
        <OkrProvider>
          <Routes>
              <Route path="/" element={<Login />}/>
              <Route path="reset-password" element={<ResetPassword />} />
              {/* protected routes */}
              <Route element={<ProtectedRoutes />}>
                <Route element={<Layout />}>
                    <Route path="home">
                      <Route index element={<Home />} />
                      {/* USUARIO 1 y 2 */}
                      <Route path='empresa/:id'>
                        <Route index element={<Areas />}/>
                        <Route path="powerbi/:idArea" element={<PowerBI />} />
                      </Route>
                      {/* USUARIO 3 y demás */}
                      <Route path='powerbi/:idArea'>
                        <Route index element={<PowerBI />} />
                      </Route>
                    </Route>
                    <Route path="/perfil" element={<Perfil />} />
                    <Route element={<PrivateRoutesBank />}>
                      {/* PLANES DE ACCION PLANES DE ACCION */}
                      <Route path="planes-de-accion">
                        <Route index element={<HomePlanesAccion />} />
                        {/* USUARIO 1 y 2 */}
                        <Route path='consultora/:idConsult/empresa/:id'>
                          <Route index element={<AreasPA />}/>
                          <Route path="area/:idArea">
                            <Route index element={<Gantt />} />
                            <Route path=':yearDefault/proyectos/:idProyec' element={<Proyectos />} />
                            <Route path=':yearDefault/proyectos' element={<Proyectos />} />
                          </Route>
                        </Route>
                        {/* USUARIO 3 */}
                        <Route path='area/:idArea'>
                          <Route index element={<Gantt />} />
                          <Route path=':yearDefault/proyectos/:idProyec' element={<Proyectos />} />
                          <Route path=':yearDefault/proyectos' element={<Proyectos />} />
                        </Route>
                        {/* USUARIO 4 y 5 */}
                        <Route path=':yearDefault/proyectos/:idProyec'>
                          <Route index element={<Proyectos />} />
                        </Route>
                        <Route path=':yearDefault/proyectos'>
                          <Route index element={<Proyectos />} />
                        </Route>
                      </Route>
                      {/* PLANES DE AUDITORIA PLANES DE AUDITORIA */}
                      <Route path="plan-de-auditoria">
                        <Route index element={<HomeAuditoria />} />
                        {/* USUARIO 1 y 2 */}
                        <Route path='consultora/:idConsult/empresa/:id'>
                            <Route index element={<GanttAudit />} />
                            <Route path=':yearDefault/ciclos/:idCiclo' element={<Ciclos />} />
                            <Route path=':yearDefault/ciclos' element={<Ciclos />} />
                        </Route>
                        {/* USUARIO 3, 4 y 5 */}
                        <Route index element={<GanttAudit />} />
                        <Route path=':yearDefault/ciclos/:idCiclo' element={<Ciclos />} />
                        <Route path=':yearDefault/ciclos' element={<Ciclos />} />
                      </Route>
                    </Route>
                    {/* OKR OKR OKR */}
                    <Route path="/okr">
                      <Route index element={<HomeOkr />} />
                      {/* OKR USUARIO 1 */}
                      <Route path='consultora/:idConsult/empresa/:id'>
                        <Route index element={<AreasOkr />}/>
                        <Route path='empresariales/:idOkr'>
                          <Route index element={<DetalleOkr />}/>
                          <Route path='keyresult/:idKeyResult'>
                            <Route index element={<DetalleKeyResult />}/>
                          </Route>
                        </Route>
                        <Route path="area/:idArea">
                          <Route index element={<ListaOkr />} />
                          <Route path="okr/:idOkr">
                            <Route index element={<DetalleOkr />}/>
                            <Route path='keyresult/:idKeyResult'>
                              <Route index element={<DetalleKeyResult />}/>
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                      {/* OKR USUARIO 2 */}
                      <Route path='empresa/:id'>
                        <Route index element={<AreasOkr />}/>
                        <Route path='empresariales/:idOkr'>
                          <Route index element={<DetalleOkr />}/>
                          <Route path='keyresult/:idKeyResult'>
                            <Route index element={<DetalleKeyResult />}/>
                          </Route>
                        </Route>
                        <Route path="area/:idArea">
                          <Route index element={<ListaOkr />} />
                          <Route path="okr/:idOkr">
                            <Route index element={<DetalleOkr />}/>
                            <Route path='keyresult/:idKeyResult'>
                              <Route index element={<DetalleKeyResult />}/>
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                      {/* OKR USUARIO 3 EN ADELANTE */}
                      {/* OKR PERSONALES */}
                      <Route path='personales/:idOkr'>
                        <Route index element={<DetalleOkrPersonal />}/>
                        <Route path='keyresult/:idKeyResult'>
                          <Route index element={<DetalleKeyResult />}/>
                        </Route>
                      </Route>
                      {/* OKR POR EMPRESA */}
                      <Route path='empresariales/:idOkr'>
                        <Route index element={<DetalleOkr />} />
                        <Route path='keyresult/:idKeyResult'>
                          <Route index element={<DetalleKeyResult />}/>
                        </Route>
                      </Route>
                      {/* OKR POR AREA */}
                      <Route path="area/:idArea">
                        <Route index element={<ListaOkr />} />
                        <Route path="okr/:idOkr">
                          <Route index element={<DetalleOkr />}/>
                          <Route path='keyresult/:idKeyResult'>
                            <Route index element={<DetalleKeyResult />}/>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route path="/dat-in">
                      <Route index element={<HomeDatIN />} />
                      {/* USUARIO 1 y 2 */}
                      <Route path='consultora/:idConsult/empresa/:id'>
                        <Route index element={<AreasDatIN />}/>
                        <Route path="area/:idArea">
                          <Route index element={<DatIN />} />
                        </Route>
                      </Route>
                      {/* USUARIO 3 */}
                      <Route path='area/:idArea'>
                        <Route index element={<DatIN />} />
                      </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
              </Route>
          </Routes>
        </OkrProvider>
      </PlanesProvider>
    </BrowserRouter>
  )
}

export default MyRoutes